<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="">
          <div class="jobs-category">
            <b-nav pills class="scrollmenu">
              <b-nav-item :active="viewAllJobs" @click="viewAll">All</b-nav-item>
              <b-nav-item :active="isViewBookmark" @click="viewBookmark">Saved jobs</b-nav-item>
              <b-nav-item v-if="!isPartner" :active="viewMatchJobs" @click="viewMatching">Matching jobs</b-nav-item>
              <b-nav-item v-for="(jobArea, index) in jobAreas" :key="index" 
                :active="jobArea.active"
                @click="filterJob(jobArea)">{{ jobArea.area_name }}</b-nav-item>
            </b-nav>
          </div>
        </div>
      </div>
    </div>
<div class="flex items-center md:mt-5">
    <div class="ml-auto">
      <vs-button  v-if="isEdit" @click="showAddJob()" color="primary" type="filled" size="small">Add new job</vs-button>
    </div>
  </div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <p class="mt-3" v-if="jobs.length === 0">No jobs available</p>
     
      </div>
    </div>
  
    <job-edit-popup ref="jobEditRef" />
    <job-delete-popup ref="jobDeleteRef" />
    
  </div>
</template>

<script>

import JobEditPopup from './JobEditPopup'
import JobDeletePopup from './JobDeletePopup'
import { API, graphqlOperation } from '@aws-amplify/api'
import { skillByUser } from '@/graphql/queries'

export default {
  name: 'JobPortal',
  components: {
 
    JobEditPopup,
    JobDeletePopup
  },
  data () {
    return {
      viewAllJobs: true,
      viewMatchJobs: false,
      isViewBookmark: false,
      userSkills: []
    }
  },
  watch: {
    '$route.params.user' : (user) => {
      this.$store.dispatch('job/fetchJobsAndBookmarkJobs', user)
    }
  },
  computed: {
    jobAreas () {
      return this.$store.state.job.jobAreas
    },
    isPartner () {
      return this.$store.state.auth.userData.isPartner
    },
    isEdit () {
      return this.$store.state.auth.userData.name === this.$route.params.user
    },
    jobs () {
      return this.$store.state.job.filtered
    }
  },
  mounted () {
  this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
  },
  methods: {
    getUserSkills () {
      this.$vs.loading()
      API.graphql(graphqlOperation(skillByUser, {
        user: this.$store.state.auth.userData.name
      })).then(res => {
        this.userSkills = res.data.skillByUser.items.map(skill => skill.skillLabel)
        this.$vs.loading.close()
      })
      
    },
    filterJob (jobArea) {
      this.viewMatchJobs = false
      this.viewAllJobs = false
      this.isViewBookmark = false
      this.$store.commit('job/SET_ACTIVE_JOB_FILTER', jobArea)
    },
    viewAll () {
      this.viewAllJobs = true
      this.viewMatchJobs = false
      this.isViewBookmark = false
      this.$store.commit('job/RESET_FILTER')
    },
    viewMatching () {
      this.viewMatchJobs = true
      this.viewAllJobs = false
      this.isViewBookmark = false
      this.$store.commit('job/VIEW_MATCHING', this.userSkills)
    },
    viewBookmark () {
      this.isViewBookmark = true
      this.viewAllJobs = false
      this.viewMatchJobs = false
      this.$store.commit('job/VIEW_BOOKMARK')
    },
    showAddJob () {
      this.$refs.jobEditRef.add()
    },
    toggleEditJob (job, index) {
      this.$refs.jobEditRef.edit(job, index)
    },
    toggleDeleteJob (job, index) {
      this.$refs.jobDeleteRef.delete(job, index)
    }
  },
  created () {
    this.$store.dispatch('job/fetchJobsAndBookmarkJobs', this.$route.params.user)
    this.$store.dispatch('job/fetchJobAreas')
    
    this.getUserSkills()
  }
}
</script>

