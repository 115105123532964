<template>
  <div>
    <vs-popup title="Add new job" :active.sync="popupActive">
      <div>
        <div class="vx-row mb-5">
          <div class="vx-col md:w-1/2 w-full">
            <label class="text-sm">Job Role</label>
            <span class="text-primary">*</span>
            <vs-input
              v-validate="'required'"
              data-vv-validate-on="blur"
              name="title"
              class="w-full"
              v-model="title"
            />
            <span class="text-danger text-sm">{{ errors.first('title') }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full">
            <label class="text-sm">Select Type</label>
            <span class="text-primary">*</span>
            <vs-select class="w-full" v-model="type">
              <vs-select-item :key="index" :value="item.jobtype" :text="item.jobtype" v-for="(item, index) in typeList" />
            </vs-select>
            <span class="text-danger text-sm">{{ errors.first('type') }}</span>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col md:w-1/2 w-full">
            <label class="text-sm">Job Area</label>
            <span class="text-primary">*</span>
            <vs-select class="w-full" v-model="jobArea">
              <vs-select-item :key="index" :value="item.area_name" :text="item.area_name" v-for="(item, index) in jobAreaList" />
            </vs-select>
            <span class="text-danger text-sm">{{ errors.first('jobArea') }}</span>
          </div>
          <div class="vx-col md:w-1/2 w-full">
            <label class="text-sm">Select Skills</label>
            <span class="text-primary">*</span>
            <div class="w-full">
            <vs-select multiple class="w-full" v-model="skills">
              <vs-select-item :key="index" :value="item.sub_skill_name" :text="item.sub_skill_name" v-for="(item, index) in skillList" />
            </vs-select>
            <span class="text-danger text-sm">{{ errors.first('skill') }}</span>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col md:w-1/2 w-full">
            <div class="w-full">
              <label class="vs-input--label"> Salary</label>
              <span class="text-primary">*</span>
              <vs-input
                v-validate="'required'"
                data-vv-validate-on="blur"
                name="salary"
                class="w-full"
                v-model="salary"
              />
            <span class="text-danger text-sm">{{ errors.first('salary') }}</span>
            </div>
          </div>
          <div class="vx-col md:w-1/2 w-full">
            <div class="w-full">
              <label>Weekly Commitment</label>
              <span class="text-primary">*</span>
              <vs-input
                v-validate="'required'"
                data-vv-validate-on="blur"
                name="commitment"
                class="w-full"
                v-model="commitment"
              />
              <span class="text-danger text-sm">{{ errors.first('commitment') }}</span>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col w-full">
            <div class="w-full">
              <label class="vs-input--label">Job Length</label><span class="text-primary">*</span>
              <vs-input
                data-vv-validate-on="blur"
                v-validate="'required'"
                name="length"
                class="w-full"
                v-model="length"
              />
              <span class="text-danger text-sm">{{ errors.first('length') }}</span>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col sm:w-1/2">
            <label class="text-sm">Select Location</label>
            <div class="w-full">
            <vs-select class="w-full" v-model="location">
              <vs-select-item 
                :key="index" 
                :value="item.locationname" 
                :text="item.locationname" 
                v-for="(item, index) in locationList" />
            </vs-select>
            <span class="text-danger text-sm">{{ errors.first('location') }}</span>
            </div>
          </div>
          <div class="vx-col md:w-1/2 w-full">
            <div class="w-full">
              <label>Website</label
              ><span class="text-primary">*</span>
              <vs-input
                v-validate="'required'"
                data-vv-validate-on="blur"
                name="website"
                class="w-full"
                v-model="url"
              />
            <span class="text-danger text-sm">{{ errors.first('website') }}</span>
            </div>
          </div>
        </div>
        <div class="vx-row mb-5">
          <div class="vx-col w-full">
            <div class="vx-col">
              <label class="vs-input--label">Job Description</label>
              <quill-editor
                v-model="description"
                class="w-full"
                width="300px"
              ></quill-editor>
              <span class="text-danger text-sm">{{ errors.first('description') }}</span>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col">
            <vs-button @click="submitForm" class="mr-3 mb-2">
              <span v-if="isEdit == false">Submit</span>
              <span v-if="isEdit == true">Update</span>
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import axios from 'axios'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'JobEditPopup',
  components: {
    quillEditor
  },
  data () {
    return {
      popupActive: false,
      isAdd: false,
      id: '',
      title: '',
      type: '',
      jobArea: '',
      skills: [],
      salary: '',
      commitment: '',
      length: '',
      location: '',
      url: '',
      description: '',
      typeList: [],
      skillList: [],
      jobAreaList: [],
      locationList: [],
      index: ''
    }
  },
  computed: {
    isEdit () {
      return this.$store.state.auth.userData.name === this.$route.params.user
    },
    isValid () {
      return !this.errors.any()
    }
  },
  created () {
    axios.post(`${process.env.VUE_APP_BASE_URL}/getAllJobtype`)
    .then(res => this.typeList = res.data.data)

    axios.post(`${process.env.VUE_APP_BASE_URL}/getAlljobarea`)
    .then(res => {
      this.jobAreaList = res.data.data
      // console.log('jobArea', this.jobAreaList)
    })

    axios.post(`${process.env.VUE_APP_BASE_URL}/subskills`)
    .then(res => {
      this.skillList = res.data.data
      // console.log('skillList', this.skillList.map(sk => sk.sub_skill_name))
    })

    axios.post(`${process.env.VUE_APP_BASE_URL}/getAllLocations`)
    .then(res => this.locationList = res.data.data)
  },
  methods: {
    add (job) {
      this.popupActive = true
      this.isAdd = true
      this.title = '',
      this.type = '',
      this.jobArea = '',
      this.skills = [],
      this.salary = '',
      this.commitment = '',
      this.length = '',
      this.location = '',
      this.url = '',
      this.description = ''
    },
    edit (job, index) {
      this.id = job.id
      this.popupActive = true
      this.isAdd = false
      this.title = job.title
      this.type = job.type
      this.jobArea = job.jobArea
      // this.skills = job.skills
      let skills = [];
      for (let index = 0; index < job.skills.length; index++) {
        const element = job.skills[index];
        if(skills.indexOf(element) == -1){
            skills.push(element);
        }        
      }
      this.skills = skills
      this.salary = job.salary
      this.commitment = job.commitment
      this.length = job.length
      this.location = job.location
      this.url = job.url
      this.description = job.description
      this.index = index
    },
    submitForm () {
      if (!this.isValid) return
      if (this.isAdd) {
        this.$store.dispatch('job/createJob', { 
          title: this.title,
          type: this.type,
          jobArea: this.jobArea,
          skills: this.skills,
          salary: this.salary,
          commitment: this.commitment,
          length: this.length,
          location: this.location,
          url: this.url,
          description: this.description,
          author: this.$store.state.auth.userData.name,
          notify: this.$vs.notify
        }).then(() => this.popupActive = false)
      } else {
        this.$store.dispatch('job/updateJob', {
          id: this.id,
          title: this.title,
          type: this.type,
          jobArea: this.jobArea,
          skills: this.skills,
          salary: this.salary,
          commitment: this.commitment,
          length: this.length,
          location: this.location,
          url: this.url,
          description: this.description,
          author: this.$store.state.auth.userData.name,
          index: this.index,
          notify: this.$vs.notify
        }).then(() => this.popupActive = false)
      }
    }
  }
}
</script>
