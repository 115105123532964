<template>
  <div>
    <vs-popup title="Confirm" :active.sync="popupDeleteActive">
      <div class="vx-row mb-2">
        <div class="vx-col w-full mb-3 mt-3">
          <span>Are you sure you want to delete this job?</span>
      </div>
      </div>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button size="small"  @click="deleteJob()" class="mr-3 mb-2">Delete</vs-button>
          <vs-button size="small" type="border" color="warning"   @click="popupDeleteActive = false"  class="mr-3 mb-2">Cancel</vs-button>
          <!-- <vs-button color="warning" type="border" class="mb-2" @click="input9 = input10 = input11 = input12 = ''; check3 = false;">Reset</vs-button> -->
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
export default {
  name: 'JobDeletePopup',
  data () {
    return {
      popupDeleteActive: false,
      job: '',
      index: ''
    }
  },
  methods: {
    delete (job, index) {
      this.job = job
      this.index = index
      this.popupDeleteActive = true
    },
    deleteJob () {
      this.$store.dispatch('job/deleteJob', {
        job: this.job,
        index: this.index
      }).then(() => {
        this.popupDeleteActive = false
      })
    }
  }
}
</script>
